/*
 * @Author: XY
 * @Date: 2020-11-28 13:17:12
 * @LastEditors: XY
 * @LastEditTime: 2021-03-13 14:42:45
 * @Description: file content
 */
// import Vue from 'vue'
// import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Article from '../views/Article.vue'
import Sort from '../views/Sort.vue'
import ArticleDetail from '../components/ArticleDetail.vue'
import Archive from '../components/Archive.vue'
import Project from '../components/Project.vue'

// Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/article' },
  {
    path: '/article',
    neme: 'Article',
    component: Article
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  },
  {
    path: '/sort',
    name: 'Sort',
    component: Sort
  },
  
  {
    path: '/articledetail',
    neme: 'ArticleDetail',
    component: ArticleDetail
  }
  ,
  {
    path: '/archive',
    neme: 'Archive',
    component: Archive
  },
  {
    path: '/project',
    name: 'Project',
    component: Project
  }
]

const router = new createRouter({
    routes,
    history: createWebHistory()
})

export default router
