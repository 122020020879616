<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<!--
 * @Author: XY
 * @Date: 2020-12-10 10:13:05
 * @LastEditors: XY
 * @LastEditTime: 2021-03-14 15:26:48
 * @Description: file content 
-->
<template v-slot:dropdown>
  <el-container>
    <el-aside width="200px">
      <el-col  id="sort"
          :class="searchBarFixed == true ? 'isFixed' :''">
        <h5></h5>
        <el-menu
          :default-active="activeIndex"
         class="el-menu-vertical-demo"
          @select="selectSort"
          @close="handleClose"
        >
          <div v-for="sort in sorts" :key="sort.value">
            <el-menu-item
              v-if="sort.children.length === 0"
              :index="sort.value.toString()"
            >
            <el-icon><Plus /></el-icon>
              <span>{{ sort.label }}</span>
            </el-menu-item>

            <el-submenu :index="sort.value.toString()" v-else>
              <template v-slot:dropdown>
                <el-icon><Plus /></el-icon>
                <span>{{ sort.label }}</span>
              </template>
              <el-menu-item :index="sort.value.toString()">
                <el-icon><Plus /></el-icon>
                <span>分类全部文章</span>
              </el-menu-item>
              <div v-for="children in sort.children" :key="children.value">
                <el-menu-item :index="children.value.toString()">{{
                  children.label
                }}</el-menu-item>
              </div>
            </el-submenu>
          </div>
        </el-menu>
      </el-col>
    </el-aside>
    <el-main v-if="ArticleListFlag"
      ><ArticleList
        :pageInfo="pageInfo"
        msg="Welcome to Your Vue.js App"
        :articleList="articleList"
        @pageIndex="page"
    /></el-main>
  </el-container>
</template>
<script>
// @ is an alias to /src
import ArticleList from "@/components/ArticleList.vue";
import { getSorts, getArticleListBySort } from "@/utils/api";
import { generateOptions } from "@/utils/toTree";
export default {
  name: "Sort",
  components: {
    ArticleList,
  },
  data() {
    return {
      activeIndex: "1",
      ArticleListFlag: false,
      sorts: {},
      currentSid: 1,
      articleList: {},
      pageInfo: {},
      searchBarFixed: false
    };
  },
    mounted() {
    //监听页面滚动事件
    window.addEventListener("scroll", this.appScroll);
    //注意：如果由于自己的vue中的样式导致监听不到，可以尝试监听body或者'#app-root'的滚动事件
  },
  unmounted () {
  window.removeEventListener('scroll', this.appScroll)
},
  created() {
    getSorts().then((res) => {
      console.log(res.data);
      var tree = generateOptions(res.data);
      console.log(tree);
      this.$data.sorts = tree;
    });
    getArticleListBySort(this.activeIndex, 1).then((res) => {
      this.articleList = res.data;
      this.pageInfo = res.pageInformation;
    });
    this.ArticleListFlag = true;
  },
  methods: {
    handleOpen(e) {
      console.log("handleOpen");
      console.log(e);
    },
    selectSort(id) {
      // this.currentSortId = id
      // if(this.currentSortId === id){
      //   console.log("seleted")
      //   return
      // }
      this.currentSortId = id;
      getArticleListBySort(id, 1).then((res) => {
        this.articleList = res.data;
        this.pageInfo = res.pageInformation;
      });
      this.ArticleListFlag = true;
    },
    handleClose() {
      console.log("handleClose");
    },
    page(e) {
      getArticleListBySort(this.currentSid, e).then((res) => {
        this.articleList = res.data;
        this.pageInfo = res.pageInformation;
      });
    },
    appScroll() {
       let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
       let offsetTop = document.querySelector('#sort').offsetTop
       
      if (scrollTop > offsetTop) {
        this.searchBarFixed = true
        
       } else {
      this.searchBarFixed = false
       }
      
    },
  },
};
</script>
<style>
  .isFixed{
    position:fixed;
    background-color:#Fff;
    top:0;
    z-index:999;
    max-width: 200px;
  }
</style>