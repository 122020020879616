<!-- eslint-disable vue/multi-word-component-names -->
<!--
 * @Author: XY
 * @Date: 2020-11-28 16:25:08
 * @LastEditors: XY
 * @LastEditTime: 2020-11-28 18:22:45
 * @Description: file content
-->
<template>
      <el-container class="app">
<div >
          <el-timeline :reverse="reverse">
    <el-timeline-item
      v-for="(activity, index) in activities"
      :key="index"
      :timestamp="activity.timestamp">
      {{activity.content}}
    </el-timeline-item>
  </el-timeline>
</div>
          </el-container>

</template>
<script>
  export default {
    data() {
      return {
        reverse: true,
        activities: [{
          content: '活动按期开始',
          timestamp: '2018-04-15'
        }, {
          content: '通过审核',
          timestamp: '2018-04-13'
        }, {
          content: '创建成功',
          timestamp: '2018-04-11'
        }]
      };
    }
  };
</script>
<style >
.app{
  margin-top:20px;
}
</style>