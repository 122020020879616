<template>
  <div id="app">
    <div id="left" class="column"></div>
    <div id="center" class="column">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        router="true"
      >
        <el-menu-item index="article"> 文章 </el-menu-item>
        <el-menu-item index="Sort"> 分类 </el-menu-item>
        <!-- <el-menu-item index="Archive"> 归档 </el-menu-item>
      <el-menu-item index="Project"> 项目 </el-menu-item> -->
        <el-menu-item index="About"> 关于 </el-menu-item>
      </el-menu>
      <router-view />
      <el-container>
        <el-footer>
          <div class="footer_info">
            <!-- <el-divider ></el-divider> -->

            <p>&copy; 2020-2020 XY All rights reserved.</p>
            <a id="beian" href="https://beian.miit.gov.cn/" target="_blank"
              >粤ICP备2020131214号</a
            >
          </div>
        </el-footer>
      </el-container>
      <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
      <router-link to="/article">Article</router-link>
    </div>
     -->
    </div>
    <div id="right" class="column"></div>
  </div>
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      activeIndex: "article",
    };
  },
  mounted() {},
};
</script>

<!-- <style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style> -->
<style>
body {
  background-color: rgb(248, 248, 248);
}

#app {
  /* display:flex; */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  padding-left: 8%;
  padding-right: 8%;
  /* margin-left: 10%;
  margin-right: 10%;  */
}
#app .column {
}
#center {
  min-width: 600px;
}

#left {
}

#right {
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.footer_info {
  text-align: center;
  font-size: 12px;
}
#beian {
  color: black;
  text-decoration: none;
}
</style>
