<!--
 * @Author: XY
 * @Date: 2020-11-28 14:09:14
 * @LastEditors: XY
 * @LastEditTime: 2021-03-14 15:32:44
 * @Description: file content
-->

<template>
  <div>
    <el-container class="ad_contailer">
      <el-main>
        <el-row class="title" style="margin-left:10px"> {{ article.title }} </el-row>
        <el-row>
          <el-col :span="3" class="article_card_info" style="margin-left:10px"
            ><el-icon><User /></el-icon>xyaxis</el-col
          >
          <!-- <el-col :span="2" class="article_card_info"><i class="el-icon-view">100</i> </el-col>
            <el-col :span="2" class="article_card_info"><i class="el-icon-star-on">100</i> </el-col> -->
          <el-col :span="12" class="article_card_info"
            ><el-icon><Clock /></el-icon>{{ article.createTime }}</el-col
          >
        </el-row>
        <div class="device" />

        <el-row clsas="content" id="content">
          <el-card shadow="never" clsas="content" style="flex: 1;">
            <div class="markdown-body" v-html="article.content"></div>
            <!-- <el-row class="tail">
              <el-button>{{ article.tid }}</el-button>
              </el-row> -->
          </el-card>
        </el-row>
        <el-divider></el-divider>

        <!-- <el-row class="comment">
          <el-card shadow="never">
            <div>从不显示</div>
            <el-row class="tail"><el-button>dkjfnosdnvl</el-button></el-row>
          </el-card>
        </el-row> -->
      </el-main>
      <el-aside width="25%" class="aside">
        <el-card id="nav_card" :class="searchBarFixed == true ? 'isFixed' :''">
          <el-scrollbar style="height: 500px; width:100%">
            <div id="aside_nav"></div>
          </el-scrollbar>
        </el-card>
      </el-aside>
    </el-container>
  </div>
</template>
<script>
import { getArticleById } from "../utils/api";
import $ from "jquery";
import { useRoute, useRouter } from 'vue-router'

// import func from "vue-editor-bridge";

export default {
  name: "ArticleDetail",
  data() {
    return {
      article: {},
      searchBarFixed:false
    };
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
  },
  mounted() {
    //监听页面滚动事件
    window.addEventListener("scroll", this.appScroll);
    //注意：如果由于自己的vue中的样式导致监听不到，可以尝试监听body或者'#app-root'的滚动事件
  },
  unmounted () {
  window.removeEventListener('scroll', this.appScroll)
},
  created() {
    // console.log(this.$route);
    getArticleById(this.$route.query.id).then((res) => {
      this.$data.article = res.data;
      // var div = document.getElementById("content");
      // var h1 = div.getElementsByTagName("h1");

      // var aside = document.getElementById("aside_nav");
      this.$nextTick(() => {
        // if (h1) {
        //   h1.forEach(function (element, index) {
        //     console.log(index);
        //     console.log(element);
        //     var br = document.createElement("br");
        //     var a = document.createElement("a");
        //     a.href = "#" + element.childNodes[0].id;
        //     a.innerText = element.innerText;
        //     aside.appendChild(a);
        //     aside.appendChild(br);
        //   });
        // }
        var h = $(":header");
        if (h) {
          var list = [],
            headerList = [],
            idList = [];
          //console.log(list);
          var grade = 1;
          h.each(function (i) {
            $(this).attr("id", i);
            idList.push(i);
            if ($(this).get(0).tagName > headerList[i - 1]) {
              if (grade < 3) {
                // console.log(true);
                list[list.length - 1] =
                  list[list.length - 1].slice(
                    0,
                    list[list.length - 1].length - 5 * grade
                  ) +
                  '<ul class="nav_ul"><li class=""><a class="nav_a" href="#' +
                  i +
                  '">' +
                  $(this).text() +
                  "</a></li></ul>" +
                  list[list.length - 1].slice(
                    list[list.length - 1].length - 5 * grade
                  );
                grade++;
              }
            } else {
              list.push(
                '<ul class="nav_ul"><li class=""><a class="nav_a" href="#' +
                  i +
                  '">' +
                  $(this).text() +
                  "</a></li></ul>"
              );
              grade = 1;
              // addList(i, $(this))
            }

            headerList.push($(this).get(0).tagName);
          });
          //console.log(list);
          $("#aside_nav").append(list);
        }

        
        //console.log(h);
        //console.log(headerList);
      });
    });
  },
  methods: {
    openArticle() {},
    appScroll() {
       let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
       let offsetTop = document.querySelector('#nav_card').offsetTop
       //console.log(offsetTop)
      if (scrollTop > offsetTop) {
        //console.log("'dsc")
        this.searchBarFixed = true
       } else {
                 //console.log("'1324")

      this.searchBarFixed = false
       }
         // console.log(scrollTop)

        //let oneHeight = this.$refs.scrollOne.offsetHeight ;
        //let twoHeight = this.$refs.scrollTwo.offsetHeight + oneHeight;
        //let threeHeight = this.$refs.scrollThree.offsetHeight + twoHeight;
        // 
        // console.log(scrollTop)
        // var offsetTop = document.querySelector('#').offsetTop;
        // console.log(offsetTop)
      
    },
  },
};
</script>

<style>
/* @import url("https://cdn.bootcss.com/github-markdown-css/2.10.0/github-markdown.min.css"); */
@import '../assets/github-markdown.min.css';
.ad_contailer {
  flex-direction: row;
}

.el-row {
  margin-bottom: 2px;
}
.title {
  margin-bottom: 20px;
  font-size: 30px;
  overflow:hidden;
  text-overflow: ellipsis;
}

.info {
  margin-bottom: 20px;

  float: left;
  font-size: 10px;
}

.content {
  margin-bottom: 20px;
  text-align: left;
}

.tail {
  margin-top: 20px;
}
.comment {
}
.sent_comment {
}
.tag {
  float: right;
}
.device {
  padding: 0 20px 0;
  margin: 20px 0;
  line-height: 1px;
  border-left: 200px solid #ddd;
  border-right: 200px solid #ddd;
  text-align: center;
}
.nav_ul {
  list-style-type: none;
}
.nav_a:link {
  color: rgb(0, 0, 0);
  text-decoration: none;
}
.nav_a:visited {
  color: rgb(0, 0, 0);
  text-decoration: none;
}
.nav_a:hover {
  text-decoration: underline;
}
.nav_ul:active {
  color: rgb(57, 255, 7);
}
  .isFixed{
    position:fixed;
    background-color:#Fff;
    top:0;
    z-index:999;
      max-height: 500px;
      max-width: 20%;
  }
  .aside{
    margin-top: 115px;
  }

</style>
