<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!--
 * @Author: XY
 * @Date: 2020-11-28 14:09:14
 * @LastEditors: XY
 * @LastEditTime: 2021-03-12 22:40:25
 * @Description: file content
-->
<template>
  <div>
    <el-card
      shadow="hover"
      class="article_card"
       v-for="article in articleList" :key="article.id"
       @click="openArticle(article)"
    >
      <el-container>
        <el-main>
          <el-row class="article_card_title">{{article.title}}</el-row>
          <el-row class="article_card_abstract"
            >{{article.summary}}</el-row
          >
          <div class="device" />

          <el-row class="article_card_row_info" >
            <el-col :span="3" class="article_card_info"><el-icon><User /></el-icon>xyaxis</el-col> 
            <!-- <el-col :span="2" class="article_card_info"><i class="el-icon-view">100</i> </el-col>
            <el-col :span="2" class="article_card_info"><i class="el-icon-star-on">100</i> </el-col> -->
            <el-col :span="12" class="article_card_info"><el-icon><Clock /></el-icon>{{article.createTime}}</el-col>
          </el-row>
        </el-main>
        <el-aside width="16%"><el-image :src="article.diagramUrl" /></el-aside>
      </el-container>
    </el-card>
    <el-pagination
    @prev-click="prevClick"
    @next-click="nextClick"
    @current-change="currentChange"
    :hide-on-single-page="true"
  background
  layout="prev, pager, next"
  :page-count="pageInfo.pages">
</el-pagination>
  </div>
</template>
<script>
import { getArticleById } from "../utils/api";

export default {
  name: "ArticleList",
  props: {
    articleList: {},
    pageInfo: {}
  },
  methods: {
    
    openArticle(article) {
      console.log(article);
      // this.axios.get("http://localhost:8082/article/page?index=1").then((response) => {
      //   console.log(response.data);
      // });
      console.log(this.msg)
      getArticleById(60).then((res) => {
        console.log(res);
      });
      this.$router.push({ path: "/ArticleDetail", query:{id: article.id}});
    },
    prevClick(e){
      this.$emit('pageIndex', e);
      console.log(e)
    },
    nextClick(e){
      this.$emit('pageIndex', e);
      console.log(e)

    },
    currentChange(e){
      this.$emit('pageIndex', e);
      console.log(e)

    }
  },
};
</script>

<style>
.article_card {
  margin-bottom: 15px;
}
.article_card :hover{
  cursor:pointer;
}
.article_card_title {
  font-size: 18px;
  text-align: left;
}
.article_card_abstract {
  margin-top: 12px;
  font-size: 14px;
  text-align: left;
}
.article_card_info1 {
  margin-top: 20px;
  font-size: 12px;
  text-align: left;
  vertical-align:bottom;
}
.article_card_info {
  font-size: 12px;
  text-align: left;
}

.device{
    padding: 0 20px 0;
    margin: 20px 0;
    line-height: 1px;
    border-left: 200px solid #ddd;
    border-right: 200px solid #ddd;
    text-align: center;
}
</style>