<!--
 * @Author: XY
 * @Date: 2020-11-28 13:17:12
 * @LastEditors: XY
 * @LastEditTime: 2020-11-28 13:29:14
 * @Description: file content
-->
<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
        <router-link to="Article">文章 </router-link>  
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
