<!-- eslint-disable vue/multi-word-component-names -->
<!--
 * @Author: XY
 * @Date: 2020-11-28 16:31:09
 * @LastEditors: XY
 * @LastEditTime: 2020-11-28 18:17:02
 * @Description: file content
-->
<template>
    <el-container>

    <div class="app">
        <el-col :span="10" v-for="(o, index) in 2" :key="o" :offset="index > 0 ? 2 : 0">

    <el-card :body-style="{ padding: '0px' }">
      <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image">
      <div style="padding: 14px;">
        <span>好吃的汉堡</span>
        <div class="bottom clearfix">
          <time class="time"> dnsmafkno</time>
        </div>
      </div>
    </el-card>
        </el-col>
    </div>
        </el-container>

</template>
<style>
.app{
   margin-top: 20px;
    line-height: 12px;
}
  .time {
    font-size: 13px;
    color: #999;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
</style>