/*
 * @Author: XY
 * @Date: 2020-12-10 11:24:43
 * @LastEditors: XY
 * @LastEditTime: 2020-12-31 12:49:07
 * @Description: file content
 */
import request from './request'

export function getArticleList(index) {
    return request({
        url: '/article/page',
        method: 'get',
        params: {index: index, size: 10 }
    })
}

export function getArticleListBySort(sid, pageNum) {
    return request({
        url: '/article/sort',
        method: 'get',
        params: {
            sid: sid,
            pageNum: pageNum
        }
    })
}

export function getArticleListByTag(tid,pageNum) {
    return request({
        url: '/article/tag',
        method: 'get',
        params: {
            tid: tid,
            pageNum: pageNum
        }
    })
}
export function getArticleById(id) {
    return request({
        url: '/article',
        method: 'get',
        params: {id: id}
    })
}

export function getSorts() {
    return request({
        url: '/sort',
        method: 'get',
    })
}
export function getTags() {
    return request({
        url: '/tag',
        method: 'get',
    })
}