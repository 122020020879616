/*
 * @Author: XY
 * @Date: 2020-12-11 21:00:51
 * @LastEditors: XY
 * @LastEditTime: 2020-12-12 16:39:22
 * @Description: file content
 */
function generateOptions(params) {//生成Cascader级联数据
  var result = [];
  for (let param of params) {
      if (param.parentSid === "0") {//判断是否为顶层节点
        var parent = {//转换成el-Cascader可以识别的数据结构
              'label': param.name,
              'value': param.id
          }
          parent.children = getchilds(param.id, params);//获取子节点
          result.push(parent);
      }
  }
  return result;
}
var getchilds = function(id, array) {
  let childs = new Array();
  for (let arr of array) {//循环获取子节点
      if (arr.parentSid === id.toString()) {
          childs.push({
              'label': arr.name,
              'value': arr.id
          });
      }
  }
  for (let child of childs) {//获取子节点的子节点
      let childscopy = getchilds(child.value, array);//递归获取子节点
      console.log(childscopy)
      if (childscopy.length > 0) {
          child.children = childscopy;
      }
  }
  return childs;
}

export { generateOptions }