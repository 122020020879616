<!-- eslint-disable vue/multi-word-component-names -->
<!--
 * @Author: XY
 * @Date: 2020-11-28 14:40:46
 * @LastEditors: XY
 * @LastEditTime: 2020-12-31 12:49:28
 * @Description: file content
-->
<template>
  <div>
    <el-row class="row_avator">
      <div class="block">
        <el-avatar :size="100" :src="circleUrl"></el-avatar>
      </div>

      <div class="username">XY</div>
    </el-row>
    <el-divider></el-divider>
    <!-- <el-row class="row_avator">
      <p class="title">标签</p>
      <div  v-for="tag in tags" :key="tag.id">
        <el-tag class="tag" type="info" @click="tagClick(tag.id)">{{tag.name}}</el-tag>
      </div>
    </el-row> -->
    <!-- <el-divider></el-divider>

    <el-row class="row_avator">
      <p class="title">danvsajknvo</p>

      <el-avatar :size="100" :src="circleUrl"></el-avatar>
    </el-row> -->
  </div>
</template>
<script>
import { getTags } from "../utils/api"
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Aside",
  props: {
  },
  data() {
    return {
      circleUrl:
        "avatar.jpg",
      squareUrl:
        "avatar.jpg",
      sizeList: ["large", "medium", "small"],
      tags: {}
    };
  },
  created () {
    getTags().then(res => {
      console.log(res)
      this.tags = res.data
    })
  },
  methods: {
    tagClick(id){
      console.log(id)
      this.$emit('TagId', id);
    }
  }
};
</script>
<style>
.row_avator {
  margin-top: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.row_tag {
  margin-bottom: 15px;
}
.row_bottom {
  margin-bottom: 15px;
}
.username {
  font-size: 18px;
  margin-top: 15px;

}
.title {
  font-size: 18px;
  color: dimgray;
}
.tag {
  margin-top: 5px;
  margin-left: 5px;
}
.tag :hover{
cursor:pointer;
}
</style>