/*
 * @Author: XY
 * @Date: 2020-12-10 11:14:04
 * @LastEditors: XY
 * @LastEditTime: 2021-03-14 16:03:34
 * @Description: file content
 */
import axios from 'axios'  // 引入axios
import store from '../store'  //引入Vuex的Store
// import { getToken } from '@/utils/auth'  //一个获取cookie中token的工具类
// import Cookies from 'js-cookie'  //引入cookie

// 创建axios实例，在这里可以设置请求的默认配置
const service = axios.create({
  baseURL: "https://www.bitaxes.com/api", //根据自己配置的反向代理去设置不同环境的baeUrl
  // baseURL: "http://1.12.218.211:8082", //根据自己配置的反向代理去设置不同环境的baeUrl
  // baseURL: "http://182.254.141.65:8082", //根据自己配置的反向代理去设置不同环境的baeUrl
  //baseURL: "http://localhost:8082", //根据自己配置的反向代理去设置不同环境的baeUrl
  timeout: 50000, // 请求超时时间,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
  // withCredentials : true
})

// request 拦截器(言外之意就是在发起请求前做什么)
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      // config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    // 对请求错误做些什么
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非0是抛错 可结合自己业务进行修改
     */
    // let message
    // let title
    // let confirmButtonText
    // let cancelButtonText
    const res = response.data
    console.log(response)
    if (res.code !== 20000) {
      // Message({
      //   message: res.message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      if (res.code === 50008 || res.code === 50012 || res.code === '50014' || res.code === '333') {
        // if (Cookies.get('language') === 'zh') {
        //   message = '登录超时，请重新登录!'
        //   title = '确定登出'
        //   confirmButtonText = '重新登录'
        //   cancelButtonText = '取消'
        // }
        // MessageBox.confirm(
        //   message,
        //   title,
        //   {
        //     confirmButtonText: confirmButtonText,
        //     cancelButtonText: cancelButtonText,
        //     type: 'warning',
        //     showCancelButton: false
        //   }
        // ).then(() => {
        //   store.dispatch('FedLogOut').then(() => {
        //     location.reload() // 为了重新实例化vue-router对象 避免bug
        //   })
        // }).catch(() => {
        //   store.dispatch('FedLogOut').then(() => {
        //     location.reload() // 为了重新实例化vue-router对象 避免bug
        //   })
        // })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      console.log(response)
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })

    return Promise.reject(error)
  }
)

export default service
