<!--
 * @Author: XY
 * @Date: 2020-11-28 13:23:58
 * @LastEditors: XY
 * @LastEditTime: 2020-12-28 12:47:54
 * @Description: file content
-->
<template>
  <div>
    <el-container>
      <el-main>
        <ArticleList
          :pageInfo="pageInfo"
          :articleList="articleList"
          @pageIndex="page"
        />
      </el-main>
      <el-aside width="20%">
        <Aside @TagId="tagList" />
      </el-aside>
    </el-container>
  </div>
</template>
<script>
// @ is an alias to /src
import { getArticleList, getArticleListByTag } from "../utils/api";
import ArticleList from "@/components/ArticleList.vue";
import Aside from "@/components/Aside.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Article",
  components: {
    ArticleList,
    Aside,
  },
  data() {
    return {
      articleList: {},
      TagId: -1, //-1为普通列表
      pageInfo: {},
    };
  },

  beforeCreate() {
    getArticleList(1).then((res) => {
      console.log(res);
      this.$data.articleList = res.data;
      this.$data.pageInfo = res.pageInformation;
      console.log(this.$data.articleList);
    });
  },
  methods: {
    page(e) {
      if (this.TagId === -1) {
        getArticleList(e).then((res) => {
          this.$data.articleList = res.data;
          this.$data.pageInfo = res.pageInformation;
          console.log(res.pageInformation);
        });
      } else {
        getArticleListByTag(this.TagId, e).then((res) => {
          console.log(res);
          this.$data.pageInfo = res.pageInformation;
          this.$data.articleList = res.data;
        });
      }
    },
    tagList(id) {
      this.TagId = id;
      getArticleListByTag(id, 1).then((res) => {
        console.log(res);
        this.$data.pageInfo = res.pageInformation;
        this.$data.articleList = res.data;
      });
    },
  },
};
</script>
